<template>
  <div class="OrderDetailPage">
    <!-- 地图容器 -->
    <div class="orderDetailTitle" v-if="orderDetail">
      <div class="topDiv">
        <img class="mini_logo" :src="setIcon(orderDetail.trafficType)">
        <span class="p1">{{ orderDetail.routeName }}</span>
      </div>

      <div
          class="amount"
          :class="
          formatOrderStatus(orderDetail.orderStatus)
            ? formatOrderStatus(orderDetail.orderStatus).detailPriceColor
            : ''
        "
      >
        {{
          formatOrderStatus(orderDetail.orderStatus)
              ? formatOrderStatus(orderDetail.orderStatus).addPlus
                  ? `+${orderDetail.payAmount}`
                  : formatOrderStatus(orderDetail.orderStatus).positive == false
                      ? "-" + orderDetail.payAmount
                      : orderDetail.payAmount
              : ""
        }}
      </div>
      <div
          class="billState"
          :class="
          formatOrderStatus(orderDetail.orderStatus)
            ? formatOrderStatus(orderDetail.orderStatus).detailColor
            : ''
        "
      >
        {{
          formatOrderStatus(orderDetail.orderStatus)
              ? formatOrderStatus(orderDetail.orderStatus).detailText
                  ? formatOrderStatus(orderDetail.orderStatus).detailText
                  : formatOrderStatus(orderDetail.orderStatus).text
              : ""
        }}
      </div>
    </div>
    <div class="billDetail" v-if="orderDetail">
      <div>
        <p>出行方式</p>
        <p>{{ orderDetail.payWay }}</p>
      </div>
      <div>
        <p>扣款方式</p>
        <p>{{ formatPayType2(orderDetail.payType) }}</p>
      </div>
      <div>
        <p>账单金额</p>
        <p>{{ orderDetail.orderPrice }}元</p>
      </div>
      <div v-if="orderDetail.changeDiscountAmount && orderDetail.changeDiscountAmount > 0">
        <p>换乘优惠</p>
        <p style="color: #fa6221">{{ orderDetail.changeDiscountAmount }}元</p>
      </div>
      <div v-if="orderDetail.thirdDiscountAmount && orderDetail.thirdDiscountAmount > 0">
        <p>折扣优惠</p>
        <p style="color: #fa6221">{{ orderDetail.thirdDiscountAmount }}元</p>
      </div>
      <div v-if="orderDetail.discountAmount && orderDetail.discountAmount > 0">
        <p>卡券优惠</p>
        <p style="color: #fa6221">{{ orderDetail.discountAmount }}元</p>
      </div>
      <div v-if="orderDetail.payDiscountAmount != '0.00'">
        <p>支付优惠</p>
        <p style="color: #fa6221">{{ orderDetail.payDiscountAmount }}元</p>
      </div>
      <div>
        <p>出行时间</p>
        <p>{{ orderDetail.inTime }}</p>
      </div>
      <div>
        <p>扣款时间</p>
        <p>{{ orderDetail.consumeTime }}</p>
      </div>
      <div v-if="orderDetail.orderId">
        <p>订单号</p>
        <p>{{ orderDetail.orderId }}</p>
      </div>
      <div v-if="orderDetail.transId">
        <p>商户单号</p>
        <p>{{ orderDetail.transId }}</p>
      </div>
      <div v-if="orderDetail.orderDesc">
        <p>订单说明</p>
        <p>{{ orderDetail.orderDesc }}</p>
      </div>
      <div v-if="orderDetail.refundTime">
        <p>退款时间</p>
        <p>{{ orderDetail.refundTime }}</p>
      </div>
      <p class="maasBox" v-if="orderDetail.subPlanId" @click="jumpMaas(orderDetail.subPlanId)">查看智能出行行程</p>
    </div>

    <div
        class="payBtn_box"
        v-if="
        orderDetail &&
        (orderDetail.orderStatus == 'OFF_REFUND_FAIL' ||
          orderDetail.orderStatus == 'ON_REFUND_FAIL' ||
          orderDetail.orderStatus == 'FAIL' ||
          orderDetail.orderStatus == 'CONTRACT_NOT_EXIST' ||
          orderDetail.orderStatus == 'NOTENOUGH' ||
          orderDetail.orderStatus == 'REQUEST_BLOCKED' ||
          orderDetail.orderStatus == 'LIMIT')
      "
    >
      <div class="payBtn" @click="clickDeductOrderBtn">
        <span>补缴</span>
      </div>
    </div>

    <!-- 补缴confirm模态框 -->
    <van-dialog
        v-model="deductOrderConfirmDialog"
        title="提示"
        confirm-button-text="去切换"
        confirm-button-color="#0093FF"
        :show-cancel-button="true"
        @confirm="jumpPayManage()"
    >
      <p class="dialogContent">
        {{ deductOrderDialogText }}
      </p>
    </van-dialog>
    <!-- 补缴反馈模态框 -->
    <van-dialog
        v-model="deductOrderDialog"
        title="提示"
        confirm-button-text="确定"
        confirm-button-color="#0093FF"
    >
      <div class="dialogContent">
        <p>
          {{ deductOrderDialogText }}
        </p>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {orderStatus} from "@/common/js/orderStatus";
import {busCouponOrderDetail, busOrderDetail, deductOrder} from "@/api/HttpService";
import busIcon from "@/assets/icon_bus.png";
import waterBusIcon from "@/assets/icon_water_bus.png";
import metroIcon from "@/assets/icon_metro.png";

export default {
  name: "BillDetailPage",
  mounted: async function () {
    document.title = "账单详情";
    this.getParam()
    if (this.couponNo) {
      await this.getCouponOrderDetail();
      return
    }
    if (!this.orderId){
      this.orderId = this.$route.params.orderId;
    }
    await this.getOrderDetail();
  },
  data: function () {
    return {
      orderId: null,
      loading: false,
      orderDetail: null,
      deductOrderConfirmDialog: false,
      deductOrderDialog: false,
      deductOrderDialogText: "",
      couponNo: '',//携带值
      isFromWXMP:localStorage.getItem("hostProgram") == "wxmp",//宿主来源
    };
  },
  methods: {
    //拿到携带参数
    getParam: function () {
      var url = window.location.href;             //获取当前url
      console.log("获取" + url)
      if (url.indexOf("?") == -1) {
        return this.couponNo = ''
      }
      var cs = url.split('?')[1];                //获取?之后的参数字符串
      console.log("携带的参数值split" + cs.split('=')[1])
      if (cs.split('=')[0] == "id"){
        this.orderId = cs.split('=')[1]
        return this.couponNo = ''
      }
      if (cs.split('=')[0] == "couponNo"){
        this.couponNo = cs.split('=')[1]
        return this.orderId = ''
      }
    },
    // 优惠券消费查看订单详情
    getCouponOrderDetail: async function () {
      let params = {
        couponNo: this.couponNo,
      };
      this.loading = true;
      await busCouponOrderDetail(params)
          .then(({ res }) => {
            this.loading = false;
            console.log(res.data);
            if (
                res.originalData.bizCode == "SUCCESS" &&
                res.originalData.code == "SUCCESS"
            ) {
              this.orderDetail = res.data;
            } else {
              this.$toast(res.originalData.bizMsg);
            }
          })
          .catch((err) => {
            console.log(err);
            this.$toast("网络异常");
            this.loading = false;
          });
    },
    // 获取订单详情
    getOrderDetail: async function () {
      let params = {
        orderId: this.orderId,
      };
      this.loading = true;
      await busOrderDetail(params)
          .then(({ res }) => {
            this.loading = false;
            console.log(res);
            if (
                res.originalData.bizCode == "SUCCESS" &&
                res.originalData.code == "SUCCESS"
            ) {
              this.orderDetail = res.data;
            } else {
              this.$toast(res.originalData.bizMsg);
            }
          })
          .catch((err) => {
            console.log(err);
            this.$toast("网络异常");
            this.loading = false;
          });
    },
    // 将交易状态转为中文(公交)
    formatOrderStatus: function (status) {
      return orderStatus[status];
    },
    // 将交易类型转换为中文  详情页
    formatPayType2: function (payType) {
      switch (payType) {
        case "wallet":
          return "余额付款";
        case "wechat":
          return "微信先乘后付";
        case "unionpay":
          return "云闪付无感支付";
        case "lzccb":
          return "柳州银行卡支付";
        case "alipay":
          return "支付宝先乘后付";
        default:
          return "未知";

      }
    },
    //设置icon
    setIcon: function (type){
      switch (type) {
        case "bus":
          return busIcon
        case "ship":
          return waterBusIcon
        case "track":
          return metroIcon
        default:
          return busIcon
      }
    },
    //补缴跳转支付管理页
    jumpPayManage: function (){
      window.location.href = "liangla://mine/pay_method";
    },

    //跳转到maas
    jumpMaas:function (planId) {
      var _url =
          window.location.host.match(/test/) == null
              ? "https://h5-lzgj.brightcns.com/MaaS-v2/#/IndexPage?planId=" + planId
              : "https://lzgj.test.brightcns.cn/MaaS-v2/#/IndexPage?planId=" + planId;
      window.location.href = _url;
    },

    // 点击补缴按钮
    clickDeductOrderBtn: function () {
      this.deductOrderAction()
    },
    // 执行补缴
    deductOrderAction: async function () {
      this.loading = true;
      let params = {
        orderId: this.orderId,
      };
      deductOrder(params)
          .then(({ res }) => {
            console.log(res);
            this.loading = false;
            if (res.originalData.bizCode == "SUCCESS") {
              this.orderDetail = res.data;
              switch (res.data.orderStatus) {
                case "CREATE":
                  this.$toast("交易中");
                  break;
                case "SUCCESS":
                  this.$toast("补缴成功");
                  break;
                case "LIMIT":
                  if (this.isFromWXMP){
                    this.deductOrderDialogText = this.orderDetail.orderDesc
                    this.deductOrderDialog = true;
                  }else{
                    this.deductOrderDialogText = this.orderDetail.orderDesc
                    this.deductOrderConfirmDialog = true;
                  }
                  break;
                case "FAIL":
                case "JF":
                case "CONTRACT_NOT_EXIST":
                case "NOTENOUGH":
                case "REQUEST_BLOCKED":
                  this.$toast("补缴失败:" + res.data.orderDesc);
                  break;
              }
            }else{
              this.$toast("网络异常");
            }
          })
          .catch((err) => {
            this.loading = false;
            console.log(err)
            this.$toast("网络异常");
          });
    },
  },
}
</script>

<style lang="scss" scoped>
@import "BillDetailPage";
</style>
