<template>
  <div>
    <transition name="fade">
      <!-- <loading v-show="loading"></loading> -->
    </transition>
  </div>
</template>

<script>
// 单独引用axios
import axios from "axios";

export default {
  mounted: async function () {
    document.title = "出行账单";
    window.localStorage.setItem("hostProgram", "DXLCAPP");
    // 先判断有没有上次残留的sessionId
    if (window.localStorage.getItem("sessionId") != null) {
      console.log("有发现有sessionId啊")
      await this.$router.replace({
        name: "BillListPage",
      });
    } else {
      // 查看是否有authCode和clientId
      let authCode = this.GetQueryString("authCode");
      let clientId = this.GetQueryString("clientId");
      if (authCode && clientId) {
        // 已授权
        var url =
          window.location.host.match(/test/) == null
            ? "/static/api/oauth/accessToken"
            : "/static/api/oauth/accessToken2";
        // 获取sessionId
        let params = {
          authCode: authCode,
          clientId: clientId,
          grantType: "authorization_code",
        };
        var queryString = [];
        for (var key in params) {
          queryString.push("".concat(key, "=").concat(params[key]));
        }

        if (queryString.length > 0) {
          queryString = queryString.join("&");
          url += "?".concat(queryString);
        }
        await axios.get(url).then((res) => {
          if (res.data.bizCode == "SUCCESS" && res.data.code == "SUCCESS") {
            // 拿到了sessionId
            var result = JSON.parse(res.data.bizResp);
            window.localStorage.setItem("sessionId", result.sessionId);
            console.log("sessionId换取成功"+result.sessionId);
            console.log("sessionId换取成功window.localStorage"+window.localStorage.getItem("sessionId"));
            this.$router.replace({
              name: "BillListPage",
            });
          } else {
            // 换取sessionId失败 重新授权
            console.log("sessionId换取失败");
            setTimeout(() => {
              let _url =
                  window.location.host.match(/test/) == null
                      ? "https://open.brightcns.com/api/oauth/authorize?clientId=4825428266737207706&scope=auth_base&redirectUri=https%3A%2F%2Fh5-lzgj.brightcns.com%2Fdxlcbill%2F%23%2FOauthPage&state=123"
                      : "https://open.test.brightcns.cn/api/oauth/authorize?clientId=4825428266737207706&scope=auth_base&redirectUri=https%3A%2F%2Flzgj.test.brightcns.cn%2Fdxlcbill%2F%23%2FOauthPage&state=123";
              window.location.href = _url;
            }, 0);
          }
        });
      } else {
        // 未授权
        console.log("未授权");
        setTimeout(() => {
          let _url =
              window.location.host.match(/test/) == null
                  ? "https://open.brightcns.com/api/oauth/authorize?clientId=4825428266737207706&scope=auth_base&redirectUri=https%3A%2F%2Fh5-lzgj.brightcns.com%2Fdxlcbill%2F%23%2FOauthPage&state=123"
                  : "https://open.test.brightcns.cn/api/oauth/authorize?clientId=4825428266737207706&scope=auth_base&redirectUri=https%3A%2F%2Flzgj.test.brightcns.cn%2Fdxlcbill%2F%23%2FOauthPage&state=123";
          window.location.href = _url;
        }, 0);
      }
    }
  },
  methods: {
    GetQueryString: function (name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },

  },
};
</script>

<style>
</style>
